import { useState } from 'react';
import {
  Button,
  Modal,
  Table,
} from 'antd';
import {
  HistoryIcon,
} from './Icons';
import {
  getLocaleDateTimeWithUtcOffset,
} from '../legacy/LegacyFacade';

import './SubjectLogRowAuditTrailDisplayControl.scss';

const subjectLogRowAuditTrailTableColumns = [
  {
    title: 'When',
    dataIndex: 'date',
    key: 'date',
    render: (epochTime) => getLocaleDateTimeWithUtcOffset(epochTime),
  },
  {
    title: 'Who',
    dataIndex: 'user_id',
    render: (text, record) => record?.userData
      ? `${record.userData.firstName
          } ${record.userData.lastName
          }, ${record.userData.affiliation}`
      : text ?? '[No user data provided]', 

  },
  {
    title: 'Why',
    dataIndex: 'reason',
    render: (reason) => reason ?? '[No reason provided]',
  },
  {
    title: 'Value',
    dataIndex: 'status',
    render: (status) => status ? 'Crossed Out' : 'Crossout Removed',
  },
];

const SubjectLogRowAuditTrailTable = ({
  crossoutAuditData,
}) => {

  return (
    <Table { ...{
      className: 'SubjectLogRowAuditTrailTable',
      dataSource: crossoutAuditData,
      columns: subjectLogRowAuditTrailTableColumns,
      pagination: false,
    }}/>
  );
};


const SubjectLogRowAuditTrailDisplay = ({
  isAuditTrailVisible,
  closeAuditTrail,
  procedureRowNumber,
  ...rest
}) => {
  if(!isAuditTrailVisible) {
    return null;
  }
  return (
    <Modal { ...{
      className: 'SubjectLogRowAuditTrailDisplay',
      title: (
        `Subject Log Row Crossout History: Row ${ procedureRowNumber + 1 }`
        ),
      open: true,
      onCancel: () => closeAuditTrail(),
      footer: null,
    }}>
      <SubjectLogRowAuditTrailTable { ...rest } />
    </Modal>

  );
};

export const SubjectLogRowAuditTrailDisplayControl = ({
  context,
  subjectLogProcedureData,
}) => {
  const [isAuditTrailVisible, setIsAuditTrailVisible] = useState(false);

  const closeAuditTrail = () => {
    setIsAuditTrailVisible(false);
  };

  const {
    procedureRowNumber,
  } = context;

  const evidenceItem = subjectLogProcedureData.evidence.find(
    (item) => item.row === procedureRowNumber
  );

  if ((evidenceItem?.crossout ?? []).length === 0) {
    return null;
  }

  return <>
    <span className='SubjectLogRowAuditTrailDisplayControl'>
      <Button
        className='icon-button'
        onClick={() => setIsAuditTrailVisible(!isAuditTrailVisible)}
      >
        <HistoryIcon
          title='Subject Log Row Crossout History'
        />
      </Button>
    </span>
    <SubjectLogRowAuditTrailDisplay { ...{
      isAuditTrailVisible,
      closeAuditTrail,
      procedureRowNumber,
      crossoutAuditData: evidenceItem?.crossout,
    }} />

  </>;
};
