import {useContext, useEffect} from 'react';
import * as dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import {Card, Checkbox, DatePicker} from 'antd';
import {AuditTrailStateContext} from './AuditTrailModalContextProvider';

// These dayjs imports and extensions prevent DatePicker's "value" prop from throwing errors like
// "clone.weekday is not a function" when user tries to pick new date values
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

export const DateRangeSelector = (props) => {
  const { RangePicker } = DatePicker;

  const {protocolId} = props;

  const {
    isEntireHistorySelected,
    setIsEntireHistorySelected,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    isSiteUpdated,
  } = useContext(AuditTrailStateContext);

  useEffect(() => {
    if (isSiteUpdated) {
      setIsEntireHistorySelected(true);
    } else if (!selectedStartDate && !selectedEndDate) {
      setIsEntireHistorySelected(true);
    }
  }, []);

  const onCheckboxAllChangeDateRange = (isChecked) => {
    setIsEntireHistorySelected(isChecked);
    if (isChecked) {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
    }
  };

  const onDateRangeChange = (dateRange) => {
    if (dateRange) {
      const [start, end] = dateRange;
      setSelectedStartDate(start || null);
      setSelectedEndDate(end || null);
    } else {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
    }
  };

  return (
    <Card
      className='DateRangeSelector'
      title='Select date range'
    >
      <p className='advice'>
        Select desired date range.
      </p>
      <Checkbox
        className='select-all'
        data-clinical-listing-date-range-check-all={protocolId}
        onChange={e => onCheckboxAllChangeDateRange(e.target.checked)}
        checked={isEntireHistorySelected}>
        Entire History
      </Checkbox>
      <RangePicker
        disabled={[isEntireHistorySelected, isEntireHistorySelected]}
        onChange={onDateRangeChange}
        // Needed for setting the initial date values
        defaultValue={[selectedStartDate, selectedEndDate]}
        // Needed for setting the updated date values
        value={[selectedStartDate, selectedEndDate]}
      />
    </Card>
  );
};
