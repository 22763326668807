export const FIELD_CHANGE = 'FIELD_CHANGE';
export const VISIT_DATE_CHANGE = 'VISIT_DATE_CHANGE';
export const CRF_PERFORMANCE_CHANGE = 'CRF_PERFORMANCE_CHANGE';
export const LOG_ENTRY_CHANGE = 'LOG_ENTRY_CHANGE';
export const LOCK_EVENT = 'LOCK_EVENT';
export const EXCEPTION_CHANGE = 'EXCEPTION_CHANGE';
export const QUERY_CHANGE = 'QUERY_CHANGE';
export const REVIEW_CHANGE = 'REVIEW_CHANGE';
export const PI_SIGN_EVENT = 'PI_SIGN_EVENT';
export const SUBJECT_ID_CHANGE = 'SUBJECT_ID_CHANGE';
export const SITE_CHANGE = 'SITE_CHANGE';
export const DATAVANT_CHANGE = 'DATAVANT_CHANGE';

export const eventTypes = [
  {
    name: CRF_PERFORMANCE_CHANGE,
    description: 'Initial entry or update of visit procedure performance status',
  },
  {
    name: DATAVANT_CHANGE,
    description: 'Token generated action (not applicable to all studies)',
  },
  {
    name: EXCEPTION_CHANGE,
    description: 'Initial entry or update of exception clinical significance',
  },
  {
    name: FIELD_CHANGE,
    description: 'Initial entry or update of any data field',
  },
  {
    name: LOCK_EVENT,
    description: 'Lock/ unlock action to any site, subject, visit or subject log.',
  },
  {
    name: LOG_ENTRY_CHANGE,
    description: 'Log row starts and crossouts, or creation of an exception',
  },
  {
    name: PI_SIGN_EVENT,
    description: 'At the subject level, PI signature actions and unsign events (that have taken place since this functionality was implemented April 11, 2024)',
  },
  {
    name: QUERY_CHANGE,
    description: 'Query status change (open, answered, closed) and comment (if applicable)',
  },
  {
    name: REVIEW_CHANGE,
    description: 'Review status change (open, submitted, review comment, reviewed) and comment (if applicable)',
  },
  {
    name: SITE_CHANGE,
    description: 'Site changes for subjects who have been transferred between sites',
  },
  {
    name: SUBJECT_ID_CHANGE,
    description: 'Initial entry or update of subject ID',
  },
  {
    name: VISIT_DATE_CHANGE,
    description: 'Completion of visit performance status and initial entry of or update to visit date',
  },
];
