import { useState, useRef, useEffect } from 'react';
import { Table, Input, Select, Button, Drawer, Tooltip } from 'antd';
import {
  FunnelPlotFilled,
  SearchOutlined,
  FilterFilled,
} from '@ant-design/icons';
import { SubjectContextFilterControl } from './SubjectContextFilterControl';
import { selectSite, selectSubject } from '../legacy/LegacyFacade';

import './QueryTable.scss';

const SiteNumberSiteSerialNumberFilter = props => {

  const {
    onFilterChange,
  } = props;

  console.log({
    what: 'SiteNumberSiteSerialNumberFilter',
    props,
  });

  const [ displayValue, setDisplayValue ] = useState('');

  const onSearch = value => {

    setDisplayValue(value);

    if(!value || value.length === 0) {
      onFilterChange('site-serial-number', null);
      return;
    }
    const [ siteNumber, siteSerialNumber ] = value.split('-');
    onFilterChange( 'site-serial-number', {
      siteNumber,
      siteSerialNumber,
    } );

  };

  return (
    <Input.Search
      placeholder='Exact Number'
      allowClear={true}
      onBlur={() => {
        onSearch(displayValue);
      }}
      onChange={e => {
        setDisplayValue(e.target.value);
      }}
      onSearch={value => {
        onSearch(displayValue);
      }}
    />
  );
};

const SiteSelector = props => {

  const {
    siteItems,
    
    context,
    setContext,
    clearContext,

  } = props;

  const onSelect = siteId => {

    const newItem = siteItems.find(s => s.context.siteId === siteId);

    setContext( newItem.context );
  };

  const onClear = () => clearContext();

  const options = siteItems.map(
    s => ({ value: s.context.siteId, label: s.siteNumber }));

  return (
    <Select
      className='SiteSelector'
      value={ context?.siteId }
      placeholder='select site'
      allowClear={true}
      onSelect={onSelect}
      onClear={ onClear }
      options={ options }
    />
  );

};

const SearchFilter = props => {

  const {

    filterState,
    fieldName,
    placeholder,
    onFilterChange,
    isVisible,

  } = props;

  const [ currentValue, setCurrentValue ] = useState(null);
  const [ isOpen, setIsOpen ] = useState(false);
  const ref = useRef();

  useEffect(() => {

    if( isOpen || fieldName === filterState?.field || !currentValue) {
      return;
    }

    setCurrentValue( null );

  }, [

  currentValue,
  filterState,
  fieldName,
  isOpen,

  ]);

  useEffect(() => {
    // open here when newly visible
    // close onBlur
    if(isOpen || !isVisible) {
      return;
    }

    ref.current.focus();
    setIsOpen(true);

  }, [ isOpen, isVisible ]);

  const search = value => {

    if(!value || value.length === 0) {
      setCurrentValue( null );
      onFilterChange(fieldName, null);
      return;
    }
    onFilterChange( fieldName, value );

  };

  return (
    <Input.Search
      ref={ref}
      placeholder={placeholder}
      allowClear={true}
      onChange={e => setCurrentValue(e.target.value)}
      onSearch={search}
      onBlur={e => {
        setIsOpen(false);
        search(currentValue);
      }}
      value={currentValue}
    />
  );
};

const SelectFilter = props => {

  const {

    filterState,
    fieldName,
    placeholder,
    onFilterChange,
    isVisible,
    options,

  } = props;

  const [ currentValue, setCurrentValue ] = useState(null);
  const [ isOpen, setIsOpen ] = useState(false);
  const ref = useRef();

  useEffect(() => {

    if( isOpen || fieldName === filterState?.field || !currentValue) {
      return;
    }

    setCurrentValue( null );

  }, [

  currentValue,
  filterState,
  fieldName,
  isOpen,

  ]);

  useEffect(() => {
    // open here when newly visible
    // close onBlur
    if(isOpen || !isVisible) {
      return;
    }

    //ref.current.focus();
    setIsOpen(true);

  }, [ isOpen, isVisible ]);

  const search = value => {

    if(!value || value.length === 0) {
      setCurrentValue( null );
      onFilterChange(fieldName, null);
      return;
    }
    setCurrentValue( value );
    onFilterChange( fieldName, value );

  };

  return (
    <Select
      ref={ref}
      style={{ width: '14rem',}}
      autoFocus={true}
      allowClear={true}
      defaultOpen={true}
      filterOption={false}
      mode='single'
      placeholder={placeholder}
      onChange={value => search(value) }
      onClear={value => search(null) }
      onBlur={e => {
        setIsOpen(false);
        search(currentValue);
      }}
      value={currentValue}
      options={options}
    />
  );
};

const buildColumns = columnProps => {

  const {
    context,
    siteItems,
    filterState,
    isHideColumnSite,
    setContext,
    clearContext,
    onFilterChange,
  } = columnProps;

  const columns = [];

  columns.push({
    title: 'Query Number',
    key: 'ID',
    render: (text, record) =>
      `${record.context.presentation.siteNumber}-${record.siteSerialNumber}`,
    width: '8%',
    filterDropdown: filterProps => (
        <SiteNumberSiteSerialNumberFilter
          onFilterChange={ onFilterChange }

      />),
    filterIcon: () => (
      <FilterFilled
        className={`ColumnFilterIcon${
          (filterState?.field === 'site-serial-number') ? ' filtered' : ''}`}
      />),
  });


  if(!isHideColumnSite) {

    columns.push({
      title: 'Site',
      dataIndex: ['context', 'presentation', 'siteNumber'],
      render: (text, record) => {
        const siteItem = siteItems.find(s => 
          s.context.protocolId === record.context.protocolId
          && s.context.siteId === record.context.siteId
          );
        return (
          <Button
            type='link'
            onClick = {event => {
              event.stopPropagation();
              selectSite({
                protocolId: record.context.protocolId,
                protocolVersionName: record.context.protocolVersionName,
                siteId: record.context.siteId,
                siteDisplayName: `${
                    record.context.presentation.siteNumber
                  } - ${
                    record.context.presentation.siteName
                  } - ${
                    siteItem.piName
                  }`,
                });
            }}
          >{text}</Button>  
        );
      },
      filterDropdown: filterProps => <SiteSelector
        { ...filterProps }
        siteItems={siteItems}
        context={context}
        setContext={setContext}
        clearContext={clearContext}
        />,
      filterIcon: () => (
        <FunnelPlotFilled 
          className={`ColumnFilterIcon${context?.siteId ? ' filtered' : ''}`}
        />),
    });

  } // !isHideColumnSite

  columns.push({
    title: 'Subject',
    dataIndex: ['context', 'presentation', 'subjectName'],
    width: '8%',
    filterDropdown: filterProps => <SubjectContextFilterControl
            { ...filterProps }
            context={context}
            setContext={setContext}
            clearContext={clearContext}
          />,

    filterIcon: () => (
      <FunnelPlotFilled 
        className={`ColumnFilterIcon${context?.subjectId ? ' filtered' : ''}`}
      />),
    render: (text, record) => {

      const siteItem = siteItems.find(s => 
        s.context.protocolId === record.context.protocolId
        && s.context.siteId === record.context.siteId
        );

      return (
        <Button
          type='link'
          onClick = {event => {

            event.stopPropagation();

            selectSubject({
              protocolId: record.context.protocolId,
              protocolVersionName: record.context.protocolVersionName,
              siteId: record.context.siteId,
              siteDisplayName: `${
                  record.context.presentation.siteNumber
                } - ${
                  record.context.presentation.siteName
                } - ${
                  siteItem.piName
                }`,

              subjectName: record.context.presentation.subjectName,
              subjectId: record.context.subjectId,

            });

          }}
        >{text}</Button>  
      );
    },
  });

  columns.push({
    title: 'Visit',
    dataIndex: ['context', 'presentation', 'visitName'],
    render: (text, record) => text ?? <>&mdash;</>,
    filterIcon: filtered => (
      <SearchOutlined
        className={`ColumnFilterIcon${
          (filterState?.field === 'visit-name') ? ' filtered' : ''}`}
      />),
    filterDropdown: filterProps => (
      <SearchFilter {...{
        isVisible: filterProps.visible,
        placeholder: 'Visit begins with ...',
        fieldName: 'visit-name',
        filterState,
        onFilterChange,
      }} />),
  });

  columns.push({
    title: 'Procedure',
    dataIndex: ['context', 'presentation', 'procedureLabel'],
    render: (text, record) => {
      const isVisit = Boolean(record.context?.visitId);
      const detailText = isVisit
                           ? record.context.presentation.procedureRepeatLabel
                             ?? ''
                           : `Row ${record.context.procedureRowNumber + 1}`;

      const crossedOutLogRowTag = '#crossed-out-log-row';
      const isCrossedOutLogRow = (
        (record?.tags ?? []).includes(crossedOutLogRowTag)
      );
      const crossedOutLogRowNoticeJsx = isCrossedOutLogRow
        ? <div className='crossed-out-log-row-notice'>
            (Crossed Out)
          </div>
        : null;

      return (
      <>
        {text}
        <div className='procedure-detail'>
          {detailText}
        </div>
        {crossedOutLogRowNoticeJsx}
      </>  
      );
    },
    filterIcon: filtered => (
      <SearchOutlined
        className={`ColumnFilterIcon${
          filterState?.field === 'procedure-label' ? ' filtered' : ''}`}
      />),
    filterDropdown: filterProps => (
      <SearchFilter {...{
        isVisible: filterProps.visible,
        fieldName: 'procedure-label',
        filterState,
        onFilterChange,
        placeholder: 'Procedure begins with ...',
      }} />),
  });

  columns.push({
    title: 'Field',
    dataIndex: ['context', 'presentation', 'fieldLabel'],
    filterIcon: filtered => (
      <SearchOutlined
        className={`ColumnFilterIcon${
          filterState?.field === 'field-label' ? ' filtered' : ''}`}
      />),
    filterDropdown: filterProps => (
      <SearchFilter {...{
        isVisible: filterProps.visible,
        fieldName: 'field-label',
        filterState,
        placeholder: 'Field begins with ...',
        onFilterChange,
      }} />),
  });

  columns.push({
    title: 'Status',
    dataIndex: 'status',
    key: 'status', // used by onQueryTableChange for sorting
    defaultSortOrder: 'ascend',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    filterIcon: filtered => (
      <FilterFilled
        className={`ColumnFilterIcon${
          filterState?.field === 'status' ? ' filtered' : ''}`}
      />),
    filterDropdown: filterProps => (
      <SelectFilter {...{
        isVisible: filterProps.visible,
        placeholder: 'Select status ...',
        fieldName: 'status',
        filterState,
        onFilterChange,
        options: [
          {
            label: 'answered',
            value: 'answered',
          },
          {
            label: 'open',
            value: 'open',
          },
          {
            label: 'closed',
            value: 'closed',
          },
        ],
      }} />),
  });

  columns.push({
    title: 'Last Modified',
    dataIndex: 'date',
    key: 'date', // used by onQueryTableChange for sorting
    sortDirections: ['descend', 'ascend', 'descend'],
    sorter: true,
    render: (text, record) => {
      const timestamp = new Date(text);
      return <div>{timestamp.toLocaleDateString()}</div>;
    },
  });

  return columns;
};

const QueryDetailTitle = props => {

  const { queryItem } = props;

  if(!Boolean(queryItem)) {
    return null;
  }

  const label = queryItem.context.presentation?.fieldLabel
           ?? queryItem.context.presentation?.procedureLabel;
  return (

    <Tooltip className='EdcNewQueryFormTitle'
      placement='bottom'
      title={label}
      overlayClassName='EdcNewQueryFormTitleTooltip'
    >
      {label}
    </Tooltip>

  );         

};

export const QueryTable = (props) => {
  const {
    // data
    queryItems,
    
    // data for table controls
    context,
    siteItems,
    filterState,

    // configuration
    isLoading,
    isHideColumnSite,

    // callbacks
    onTableChange,
    displayQuery,
    onFilterChange,

    // control
    setContext,
    clearContext,

  } = props;

  const [ selectedRowKeys, setSelectedRowKeys ] = useState([]);
  const [ selectedQueryItem, setSelectedQueryItem ] = useState(null);

  useEffect(() => {

    if(selectedQueryItem && queryItems) {

      const newQueryItem = queryItems.find(item =>
        item.context.queryId === selectedQueryItem.context.queryId);

      if(newQueryItem?.etag !== selectedQueryItem.etag){
        setSelectedRowKeys(newQueryItem?.etag ? [ newQueryItem.etag ] : []);
        setSelectedQueryItem( newQueryItem );
      }

    }
  }, [ queryItems, selectedQueryItem ]);

  if (selectedQueryItem) {
    const isSiteLocked = siteItems
      .find(s => s.context.siteId === selectedQueryItem.context?.siteId)
      ?.context?.isLocked;

    // Update the lock status of the selected query with the site lock status
    // if not already locked by subject, visit or subject-log lock
    selectedQueryItem.supplement = selectedQueryItem.supplement || { locks: {} };
    selectedQueryItem.supplement.locks = selectedQueryItem.supplement.locks || {};
    if (!selectedQueryItem.supplement.locks.isLocked) {
      selectedQueryItem.supplement.locks.isLocked = isSiteLocked;
    }
  }

  return (<>
    <Table
      className='QueryTable'
      dataSource={queryItems}
      loading={isLoading}
      onChange={onTableChange}
      columns={buildColumns({
        context,
        siteItems,
        filterState,
        isHideColumnSite,
        setContext,
        clearContext,
        onFilterChange,
      })}
      rowKey={queryItem => queryItem.etag}
      size='middle'
      pagination={false}
      onRow={ (queryItem, rowIndex) => ({
        onClick: e => {
          // highlight selected row
          setSelectedRowKeys([ queryItem.etag ]);
          // copy selected item
          setSelectedQueryItem( queryItem );
        },
      }) }
      rowSelection={{
        selectedRowKeys,
        type: 'radio',
        renderCell: () => null,
      }}
    />
    <Drawer
      className='QueryTableQueryItemDetail'
      title={ <QueryDetailTitle queryItem={selectedQueryItem}/> }
      visible={ Boolean(selectedQueryItem) }
      onClose={() => {
        setSelectedRowKeys([]);
        setSelectedQueryItem(null);
      }}
    >
      { Boolean(selectedQueryItem) && displayQuery(selectedQueryItem) }
    </Drawer>
  </>);
};
